
import { Navigate } from 'react-router-dom';
import { getUser } from "../../helpers/auth-helper";

const GuestRoute = ({ children }) => {
	const user = getUser();

	if(user){
		return <Navigate to="/dashboard" replace />;
	}

	return children
};

export default GuestRoute;
