import { useTranslation } from "react-i18next";
import useCMSRepositories from "../repositories/cms";
import moment from "moment/moment"
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getCustomer, getCustomerDevice } from "../helpers/auth-helper";
import { getData } from "../helpers/api-helper";
import { CircularProgressbar,CircularProgressbarWithChildren,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function Dashboard(props){

    const { i18n, t } = useTranslation();
    const navigate = useNavigate()

    const [month_percentage,set_month_percentage] = useState(0)
    const [devices,set_devices] = useState([])
    const [today,setToday] = useState("")
    const customer = getCustomer()

    const [page,set_page] = useState(1);

    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useCMSRepositories(page,6)

    useEffect(() => {
        getThisDevice()
    },[])

    const getThisDevice=async()=>{
        var serial_number = localStorage.getItem('serial_number');
        var params = {customer_code:customer.customer_code,serial_number}
        const response = await getData(`${process.env.REACT_APP_API}/get-this-device`,params)

        if(response){
            // console.log(response?.data.data,"Data hey")
            // if(!devices){
                set_devices(response?.data.data)
                localStorage.setItem("serial_number",response?.data.data[0].serial_number)
                // sessionStorage.setItem('customer_device', JSON.stringify(response?.data.data))
            // }
            localStorage.setItem('customer_device',JSON.stringify(response?.data.data))
            var device = localStorage.getItem('customer_device')
            set_devices(response?.data?.data || JSON.parse(device))
            runCircular(response?.data?.data || JSON.parse(device))
        }else{
            var device = localStorage.getItem('customer_device')
            set_devices(JSON.parse(device))
            runCircular(JSON.parse(device))
        }
    }

    const dayLeftInAMonth=(date)=>{
        let currentDate = new Date(date)
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth();

        var currentMonthLastDate = (new Date(currentYear, currentMonth + 1, 0)).getDate() + 1;

        var daysLeftInMonth = currentMonthLastDate - currentDate.getDate();
        console.log(currentMonthLastDate,"currentMonthLastDate")
        return daysLeftInMonth
    }

    const runCircular=(data = devices)=>{

        let newDate = new Date(data[0]?.register_date)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1; // real use
        let year = newDate.getFullYear();

        let newDateExpired = new Date(data[0]?.expired_date)
        let date_expired = newDateExpired.getDate();
        let month_expired = newDateExpired.getMonth() + 1; // real use
        let year_expired = newDateExpired.getFullYear();
        let expired_date = year_expired +"-"+ month_expired +"-"+ date_expired // real use

        var warranty_month = data[0]?.warranty_month
        // console.log(warranty_month,"warranty_month")
        // console.log(expired_date,"expired_date")
        var limit_day = 0;
        var month_count = month
        var year_count = year
        var date_count = date
        var call_month = ""
        for(var i = 0;i<warranty_month;i++){
            call_month = year_count +"-"+ month_count +"-"+date_count
            // console.log(call_month,"call_month")
            var daysLeftInMonth = i == 0 ? dayLeftInAMonth(call_month)-1 : dayLeftInAMonth(call_month)
            // console.log(daysLeftInMonth,"daysLeftInMonth");
            limit_day += daysLeftInMonth
            date_count = 1
            if(month_count == 12){
                month_count = 1
                year_count += 1
            }else{
                month_count += 1
            }
            // console.log(limit_day,"limit day")
            if(warranty_month-1 == i && expired_date != call_month){
                // console.log('last months',expired_date)
                // console.log(date,"daysLeftInMonth");
                limit_day += date
                // console.log(limit_day,"limit day")
            }
        }
        let newDateToday = new Date()
        let today_date = newDateToday.getDate();
        let today_month = newDateToday.getMonth() + 1; // real use
        let today_year = newDateToday.getFullYear();
        let today = today_year +"-"+ today_month +"-"+ today_date // real use
        // let today = '2024-11-05' // simulation
        setToday(today)
        var day_left = daysBetween(today, data[0]?.expired_date)
        var day_percentage = (parseInt(day_left)/limit_day)*100;
        console.log(parseInt(day_left),"day_left")
        console.log(day_percentage,"day_percentage")    
        set_month_percentage(day_percentage)
    }

    function stripHtml(html){
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
     }

     const monthDiff=(d1, d2)=>{
         var months;
         months = (d2.getFullYear() - d1.getFullYear()) * 12;
         months -= d1.getMonth();
         months += d2.getMonth();
         return months <= 0 ? 0 : months;
    }

    function treatAsUTC(date) {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }
    
    function daysBetween(startDate, endDate) {
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
    }

    const updatePWA=()=>{
        caches.keys().then(function(cacheNames) {
            cacheNames.forEach(function(cacheName) {
                caches.delete(cacheName);
            });
        });
        window.location.reload(true);
    }
    

    return(
        <div className="dashboard-page">
                <div className="flex flex-row flex-wrap justify-between">
                    <div class="py-2 px-5 text-left w-50 rounded bg-white" style={{width:320}}>
                        <h2 className="font-bold text-xl">Chromebook Name</h2>
                        <div className="flex">
                            <label className="text-sm text-gray-500">{t('serial_number')}</label> &nbsp;
                            <label className="text-xs m-auto text-gray-500">{devices && devices[0]?.serial_number}</label> &nbsp;
                            <div className="m-auto" onClick={() => navigator.clipboard.writeText(devices && devices[0]?.serial_number)}>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.12502 8.875H2.50099C2.33684 8.87513 2.17428 8.84294 2.02257 8.78024C1.87086 8.71755 1.733 8.62558 1.61684 8.5096C1.50067 8.39362 1.40848 8.25589 1.34554 8.10428C1.2826 7.95267 1.25015 7.79016 1.25002 7.62601V2.00101C1.24988 1.83669 1.28213 1.67395 1.34496 1.52211C1.40778 1.37027 1.49994 1.23231 1.61614 1.11612C1.73233 0.999927 1.87027 0.907793 2.02211 0.844971C2.17395 0.782149 2.33667 0.749869 2.50099 0.75H8.12599C8.29023 0.75 8.45285 0.78238 8.60457 0.845261C8.75629 0.908142 8.89418 1.00029 9.01027 1.11647C9.12636 1.23265 9.21838 1.37057 9.28114 1.52234C9.3439 1.67411 9.37612 1.83677 9.37599 2.00101V2.625M6.87599 5.125H12.501C12.6651 5.12487 12.8277 5.15708 12.9794 5.21977C13.1312 5.28247 13.269 5.37444 13.3852 5.49042C13.5014 5.6064 13.5935 5.74413 13.6564 5.89574C13.7194 6.04734 13.7519 6.20986 13.752 6.37401V11.999C13.7522 12.1633 13.7199 12.3261 13.6571 12.4779C13.5942 12.6297 13.5021 12.7677 13.3859 12.8839C13.2697 13.0001 13.1317 13.0922 12.9799 13.155C12.828 13.2179 12.6653 13.2501 12.501 13.25H6.87599C6.71176 13.25 6.54914 13.2176 6.39742 13.1548C6.24569 13.0919 6.10786 12.9997 5.99178 12.8835C5.87569 12.7674 5.7836 12.6294 5.72084 12.4777C5.65808 12.3259 5.62586 12.1632 5.62599 11.999V6.37401C5.62626 6.04283 5.75797 5.7253 5.99214 5.49112C6.22632 5.25694 6.54482 5.12527 6.87599 5.125Z" stroke="#757575" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <label className="text-xs m-auto text-blue-500">{t('system_information')}</label> &nbsp;
                        <div>
                            <img src={require('../assets/images/depan1.png')} className="p-5"/>
                            {
                                devices?.length > 0 ? 
                                <div className="flex flex-row">
                                    <div className="w-28">
                                    <CircularProgressbarWithChildren value={month_percentage}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,

                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'round',

                                        // Text size
                                        textColor:'#EB008B',

                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,

                                        // Can specify path transition in more detail, or remove it entirely
                                        // pathTransition: 'none',

                                        // Colors
                                        pathColor: `#EB008B`,
                                        textColor: '#EB008B',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#3e98c7',
                                    })}>
                                    {   month_percentage > 0 ?
                                        <div className="text-center">
                                            <p className="text-[10px]">{t('expired_at')} </p>
                                            <p className="font-semibold mt-auto text-[8px]" style={{color:'#EB008B'}}>
                                                {devices && moment(devices[0].expired_date).format('Do MMMM YYYY')}
                                            </p>
                                        </div> : 
                                        <div className="text-center">
                                            <p className="font-semibold mt-auto text-[8px]" style={{color:'#EB008B'}}>
                                                {t('warranty_expired')}
                                            </p>
                                        </div>
                                    }
                                    </CircularProgressbarWithChildren>
                                    </div>
                                    <div className="ml-5 py-5">
                                        <h4 className="font-bold">{t('registered')} </h4>
                                        <p className="font-semibold mt-auto" style={{color:'#EB008B'}}>
                                            {devices && moment(devices[0].register_date).format('Do MMMM YYYY')}
                                        </p>
                                    </div>
                                </div> : 
                                <div className="flex m-auto">
                                    <svg width="30" height="30" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.25 7.43799V11.188M10.25 14.938H10.259M8.95099 2.64899L1.241 15.967C0.814003 16.707 0.598987 17.075 0.630987 17.379C0.644822 17.5097 0.685881 17.636 0.751501 17.7498C0.817122 17.8637 0.905861 17.9625 1.012 18.04C1.26 18.219 1.68601 18.219 2.53901 18.219H17.961C18.815 18.219 19.242 18.219 19.488 18.04C19.5946 17.963 19.6837 17.8643 19.7494 17.7504C19.8151 17.6364 19.8558 17.5099 19.869 17.379C19.902 17.079 19.687 16.707 19.26 15.967L11.548 2.64899C11.122 1.91399 10.909 1.549 10.631 1.422C10.5111 1.36856 10.3813 1.34094 10.25 1.34094C10.1187 1.34094 9.9889 1.36856 9.86899 1.422C9.59099 1.544 9.37799 1.91399 8.95099 2.64899Z" stroke="#D10909" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <label className="ml-2 text-xs m-auto text-gray-500">Segera daftarkan produkmu untuk mengaktifkan garansi</label> &nbsp;
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap justify-evenly">
                        <div class="text-left p-5 rounded bg-white border-b-8 beranda-item">
                            <img src={require('../assets/images/cpu.png')} className="ml-auto"/>
                            <div className="mt-32">
                                <h2 className="font-bold text-xl text-gray-600">CPU</h2>
                                <div className="flex text-center">
                                    <label className="text-sm text-gray-500">{'16%'}</label>&nbsp;|&nbsp;
                                    <label className="text-sm text-gray-500">{'60%'}</label>
                                </div>
                            </div>
                        </div>
                        <div class="text-left p-5 rounded bg-white border-b-8 beranda-item">
                            <img src={require('../assets/images/memory.png')} className="ml-auto"/>
                            <div className="mt-32">
                                <h2 className="font-bold text-xl text-gray-600">Memory</h2>
                                <div className="flex text-center">
                                    <label className="text-sm text-gray-500">{'66%'}</label>
                                </div>
                            </div>
                        </div>
                        <div class="text-left p-5 rounded bg-white border-b-8 beranda-item">
                            <img src={require('../assets/images/storage.png')} className="ml-auto"/>
                            <div className="mt-32">
                                <h2 className="font-bold text-xl text-gray-600">Storage</h2>
                                <div className="grid text-left">
                                    <label className="text-sm text-gray-500">{'C : 20% Free'}</label>
                                    <label className="text-sm text-gray-500">{'D : 20% Free'}</label>
                                    <label className="text-sm text-gray-500">{'E : 20% Free'}</label>
                                </div>
                            </div>
                        </div>
                        <div class="text-left p-5 rounded bg-white border-b-8 beranda-item">
                            <img src={require('../assets/images/battery.png')} className="ml-auto"/>
                            <div className="mt-32">
                                <h2 className="font-bold text-xl text-gray-600">Battery</h2>
                                <div className="flex text-center">
                                    <label className="text-sm text-gray-500">{'66%'}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-3 px-5 text-left w-50 rounded bg-white mt-3 flex" style={{width:320}}>
                    <label className="text-sm text-gray-500 m-auto">{t('apps_update')}</label>
                    <button type="button" onClick={() => updatePWA()} className="bg-blue-500 text-white p-2 text-sm rounded-md">
                        {t('update')}
                    </button>
                </div>
                <div class="py-2 text-left w-50 mt-1">
                    <a href="#" class="flex items-center p-2 text-gray-600 rounded-lg dark:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-200 group">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.75 1.99899H6.75C4.65 1.99899 3.6 1.999 2.798 2.409C2.09253 2.76872 1.51859 3.34196 1.158 4.047C0.750005 4.847 0.75 5.89999 0.75 7.99899V17.25C0.75 19.35 0.750005 20.4 1.158 21.203C1.51859 21.9083 2.09249 22.4819 2.798 22.842C3.598 23.25 4.65 23.25 6.75 23.25H16.001C18.101 23.25 19.151 23.25 19.953 22.842C20.6579 22.4817 21.2311 21.9081 21.591 21.203C22.001 20.403 22.001 19.35 22.001 17.25V13.25M22.152 1.85001C22.6772 2.37433 23.0352 3.04267 23.1804 3.77048C23.3257 4.49828 23.2518 5.25282 22.9681 5.93861C22.6844 6.62441 22.2036 7.21062 21.5866 7.62308C20.9696 8.03554 20.2442 8.25571 19.502 8.25571C18.7598 8.25571 18.0344 8.03554 17.4174 7.62308C16.8004 7.21062 16.3196 6.62441 16.0359 5.93861C15.7522 5.25282 15.6783 4.49828 15.8236 3.77048C15.9688 3.04267 16.3268 2.37433 16.852 1.85001C17.5553 1.14885 18.5079 0.755127 19.501 0.755127C20.4941 0.755127 21.4467 1.14885 22.15 1.85001H22.152Z" stroke="#1D1B20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="ms-3">{t('sidebar.news')}</span>
                    </a>
                    <div class="grid grid-cols-3 gap-4 mb-4">
                        {
                            listData?.data.length > 0 && listData.data.slice(0,3).map((row) => 
                                <div class="p-3 text-left rounded bg-white mt-2" onClick={() => navigate("/news/detail/"+row.id,{state:{row,side_data:listData.data.slice(0,6)}})}>
                                    <a href="#" class="flex text-xs items-center text-blue-600 rounded-lg dark:text-blue-600 hover:bg-gray-100 dark:hover:bg-gray-200 group">
                                        {t('source')}
                                    </a>
                                    <h3 className="font-bold">{row.title}</h3>
                                    <p className="text-xs font-semibold line-clamp-3">{stripHtml(row.description)}</p>
                                    <p className="mt-2 text-xs text-gray-500">{moment(row.created_at).format('LLLL')}</p>
                                </div>
                            )
                        }
                    </div>
                </div>
        </div>
    )
}