
export default {
    translation: {
        title: 'Vancare App',
        sidebar:{
            dashboard: 'Beranda',
            my_device: 'Perangkat Saya',
            interesting_promo: 'Promo Menarik',
            news: 'Berita Terbaru',
            customer_service: 'Layanan Pelanggan',
            service_center: 'Pusat Pelayanan',
            settings: 'Pengaturan',
            notifications:"Notifikasi",
        },
        empty:"Kosong",
        serial_number: "Nomor Serial",
        apps_update :"Pembaharuan Aplikasi",
        update: "Pembaharui",
        active_warranty: "Garansi Aktif",
        until: "s/d",
        system_information:"Informasi sistem",
        processor:"Prosesor",
        memory:"Memori",
        storage:"Penyimpanan",
        screen:"Layar",
        camera:"Kamera",
        material:"Material",
        system_operation:"Sistem Operasi",
        source: "Sumber",
        address: "Address",
        outlet_detail: "Detail Outlet",
        contact_name: "Nama Kontak",
        contact_email: "Email Kontak",
        contact_number: "Nomor Kontak",
        hello:"Hallo",
        send_request:"Kirim permintaan",
        request_sended:"Permintaan terkirim",
        back:"Kembali",
        welcome:"Selamat Datang!",
        news_detail:"Detail Berita",
        registered:"Terdaftar pada",
        expired_at:"Masa berlaku",
        auth:{
            login:"Masuk",
            register:"Daftar",
            sign_up:"Daftar",
            remember_me:"Ingat Saya",
            forgot_password:"Lupa Password?",
            message_verification:"Kita telah mengirim kode verifikasi ke email",
            message_name_diff:"Email sudah terdaftar, tetapi nama penuh berbeda dengan nama penuh anda sebelumnya, mohon konfirmasi nama penuh mana yang akan digunakan",
            change_email:"Ubah Email",
            full_name:"Nama Penuh",
            new_full_name:"Nama Penuh Baru",
            new_email:"Email Baru",
            phone:"Nomor HP",
            placeholder_phone:"Nomor HP mulai dengan 08xxxxxxxx",
            verify:'Verifikasi',
            resend:'Kirim Ulang',
            password_mismatch:"Password Tidak cocok",
            current_password:"Password Sekarang",
            new_password:"Password baru",
            password_is_too_short:"Password terlalu pendek (min 8 karakter)",
            apply:'Terapkan',
            cancel:'Batalkan',
            serial_number_required:"Serial Number Wajib",
            password_required:"Password Wajib",
            phone_required:"Phone Number Wajib",
            confirm_password_required:"Confirm Password Wajib",
            choose_sn:"Mohon pilih device mana yang anda gunakan",
            user:{
                not_found:"User tidak ditemukan",
                not_verified:"User Belum verifikasi token"
            },
            token:{
                verified:"Akun Terverifikasi",
                invalid:"Token Salah",
            },
            alert:{
                verify_success_but_name:"Email ini sudah terdaftar, tetapi nama penuh berbeda dengan nama penuh anda sebelumnya",
                verify_success:"Verifikasi Berhasil",
                change_email_success:"Ubah Email Berhasil",
                email_format_invalid:'Email Format Salah',
                email_format_valid:'Email Format Benar',
                register_success:"Daftar Berhasil",
                account_exists:"Akun Sudah terdaftar",
                token_resended:"Token terkirim ulang",
                change_name_success:"Ubah Nama Berhasil",
                change_password_success:"Ubah Password Berhasil",
                serial_number_already_exist:"Serial Number telah terdaftar",
                device_hasnot_registered:"Device belum terdaftar, silahkan daftar terlebih dahulu",
                serial_number_not_for_this_user:"Device Serial Number tidak terdaftar pada email anda, kirim permintaan pemindahan kepemilikan"
            }
        },
        settings:{
            change_email:"Ganti Email",
            change_password:"Ganti Password",
            change_profile:"Ganti Profil",
        },
        network_error:"Terjadi Kesalahan pada koneksi internet (Tidak ada jaringan)",
        warranty_expired:"Masa Garansi Habis"
    }
}

