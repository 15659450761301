import { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

export default function ServiceDetail(props){

    const { i18n, t } = useTranslation();
	const location = useLocation();
	const { state } = location;
    const data = state.row

    const copyToClipBoard=(data,alert)=>{
        navigator.clipboard.writeText(data)
        toast(alert)
    }

    return(
        <div className="dashboard-page">
            <div className="flex flex-row flex-wrap justify-around">
                <div class="py-3 px-5 text-center w-50 rounded bg-white" style={{width:'60%'}}>
                    <h1 className="font-extrabold text-xl">{data.name}</h1>
                    <div className="my-5">
                        <img src={data.image} className="px-5 items-center m-auto" style={{width:"50%"}}/>
                    </div>
                    <label className="text-sm">{t('address')}</label>
                    <h2 className="font-bold text-md">{data.address}</h2>
                    <div className="flex mx-auto justify-center cursor-pointer mt-2" onClick={() => window.open("https://maps.google.com?q="+data.latitude+","+data.longitude )}>
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path></svg>
                        <label>Open Map</label>
                    </div>
                </div>
                <div class="py-10 px-5 text-left w-50 rounded text-nowrap" style={{width:'35%',backgroundColor:"#E8E8E8"}}>
                    <h5 className="font-extrabold">{t("outlet_detail")}</h5>
                    <div class="grid grid-cols-2 my-5 whitespace-normal">
                        <label className="text-sm">{t("contact_name")}</label>
                        <label className="text-sm">{data.contact_name}</label>
                    </div>
                    <div class="grid grid-cols-2 my-5">
                        <div className="flex">
                        <label className="text-sm">{t("contact_number")}</label>&nbsp;
                        <svg onClick={() => copyToClipBoard(data.contact_number,t('number_copied'))} width={21} height={21} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M7 4V2H17V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7ZM7 6H5V20H19V6H17V8H7V6ZM9 4V6H15V4H9Z"></path></svg>
                        </div>
                        <label className="text-sm">{data.contact_number}</label>
                    </div>
                    <div class="grid grid-cols-2 my-5">
                        <div className="flex">
                        <label className="text-sm">{t("contact_email")}</label>&nbsp;
                        <svg onClick={() => copyToClipBoard(data.contact_email,t('email_copied'))} width={21} height={21} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M7 4V2H17V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7ZM7 6H5V20H19V6H17V8H7V6ZM9 4V6H15V4H9Z"></path></svg>
                        </div>
                        <label className="text-sm">{data.contact_email}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}