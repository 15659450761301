import React, { useEffect } from 'react';
import DashboardIcon from '../assets/svgs/Dashboard.svg'
import { useNavigate } from 'react-router-dom';
import { getUser } from '../helpers/auth-helper';

export default function Landing(props){

    const navigate = useNavigate()
    const user = getUser()
    
    useEffect(()=>{
        // console.log('landing')
        // if(!user){
        //     navigate('/login')
        // }
    },[])

    return(
      <div className="App">
        <header className="App-header">
            <img src={process.env.PUBLIC_URL + '/logo192.png'} className="App-logo" alt="logo" />
        </header>
        <body className='App-body'>
            <div className='w-50 text-center'>
            <h1 className='color-2 font-extrabold text-2xl'>Let's Get Started</h1>
            <label className='color-3 text-sm w-60'>Start register your device warranty 
            with us</label>
            </div>
            <div className='btn-group'>
            <button className='btn-primary' onClick={() => navigate('/login')}>
                Start
            </button>&nbsp;
            {/* <button className='btn-secondary btn-w-icon' onClick={() => navigate('/dashboard')}>
                <DashboardIcon width={30} height={30} fill={'#EC008C'}/>&nbsp;
                View Dashboard
            </button> */}
            </div>
        </body>
      </div>
    )
}