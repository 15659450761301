import axios from "axios"
import useSWR from "swr"

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then((res) => res.data.data)
}

const useCMSRepositories = (page,paginate) => {
    const { data, error, mutate, isValidating } = useSWR([`${process.env.REACT_APP_API}/cms?page=`+page+"&is_active="+1+"&paginate="+paginate+"&link_path="+process.env.REACT_APP_WEB_URL], fetcher)

    return {
        data,
        isLoading: !error && !data,
        error,
        isValidating,
        mutate
    }
}

export default useCMSRepositories