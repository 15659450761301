import axios from "axios"
import useSWR from "swr"
import { getToken, setToken } from "./auth-helper"
import toast from "react-hot-toast"
import { t } from "i18next"

export const newAbortSignal = (timeout) => {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeout || 0);

    return abortController.signal;
}
  
const axiosInterceptor = () => {
    axios.interceptors.response.use(undefined, (err) => {
        const { config, message } = err;
        if (!config || !config.retry) {
        return Promise.reject(err);
        }
        // retry while Network timeout or Network Error
        if (!(message.includes("canceled") || message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
        }
        config.retry -= 1;
        const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
            // console.log("retry the request", config.url);
            resolve();
        }, config.retryDelay || 1000);
        });
        return delayRetryRequest.then(() => axios(config));
    });
}
  
export const getAccessToken = async () => {

    var current_token = getToken();
    if (current_token !== '1234567890') {
        // console.log('Token is valid!');
        return;
    }

    // console.log('inside GetAccessToken -- init');

    axiosInterceptor()

    let dataAccessToken = {grant_type: "client_credentials", client_id: "arm", client_secret: "123456"};

    const options = {
        signal: newAbortSignal(process.env.REACT_APP_TIMEOUT),
        timeout: process.env.REACT_APP_TIMEOUT,
        method: 'POST',
        url: process.env.REACT_APP_REACT_APP_BACKEND_URL,
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        retry: process.env.REACT_APP_RETRY_CONNECTION, 
        retryDelay: process.env.REACT_APP_DELAY_TIME, 
        data: dataAccessToken
    };

    // console.log('inside GetAccessToken -- before sent');

    try {
        // console.log(options);
        const response = await axios.request(options)
        // console.log('inside axios response with token:');
        // console.log(response.data.access_token);
        setToken(response.data.access_token)
        // global.token = response.data.access_token;
        return response.data.access_token;
    } catch (err) {
        // console.log('inside axios response with error:');
        // console.log(err.message);
        // console.log(err);
        return '000000';
    } finally {

    }
}
  
export const postData = async (url, dataOptions) => {

    return sendAxios('POST', url, dataOptions)

}
  
export const getData = async (url, paramOptions) => {

    return sendAxios('GET', url, paramOptions)

}
  
const sendAxios = async (method, url, methodOptions) => {
  
    await getAccessToken()
  
    axiosInterceptor()
  
    var access_token = getToken()
    const options = {
        signal: newAbortSignal(process.env.REACT_APP_TIMEOUT),
        timeout: process.env.REACT_APP_TIMEOUT,
        method: method,
        url: url,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${access_token}`
        }, 
        // retry: process.env.REACT_APP_RETRY_CONNECTION, 
        // retryDelay: process.env.REACT_APP_DELAY_TIME,
        data: method === 'POST' ? methodOptions : {}, 
        params: method === 'GET' ? methodOptions : {}, 
    };
  
    // console.log('inside ' + url + ' -- before sent');
  
    try {
    //   console.log(options)
      const response = await axios.request(options)
    //   console.log('response ' + url + ' with result:');
      return response
    } catch (err) {
      var response = err?.response;
      console.log('response ' + url + ' with error:',response);
      toast.error(err?.message == 'Network Error' ? t('network_error') : t(response?.data.message))
      return response;
    }
}
  
//   export const postImage = async (imageFile,imageName) => {
  
//     const options = {
//         maxSizeMB: 0.5,
//         useWebWorker: true,
//         alwaysKeepResolution:true
//     }
//     const compressedFile = await imageCompression(imageFile, options);
//     const formData = new FormData();
//     const fileData = new File(
//         [compressedFile],
//         imageName,
//     { type :'image/jpeg'})
//     // console.log('file_tobe_uploaded',fileData)
//     formData.append("file_tobe_uploaded", fileData);
  
//     axiosInterceptor()
  
//     const optionImage = {
//       signal: newAbortSignal(process.env.REACT_APP_TIMEOUT_IMG),
//       timeout: process.env.REACT_APP_TIMEOUT_IMG,
//       method: 'POST',
//       url: process.env.REACT_APP_BACKEND_URL+'/upload_to_server.php',
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//       retry: process.env.REACT_APP_RETRY_CONNECTION, 
//       retryDelay: process.env.REACT_APP_DELAY_TIME, 
//       data: formData
//     };
  
//     // console.log('inside postImage -- before sent');
  
//     try {
//     //   console.log(optionImage)
//       var picture = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
//         imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
//           + imageName.substring(6,8) + '/' + imageName;
          
//       const response = await axios.request(optionImage)
//     //   console.log('response postImage with result:',picture);
//       return response;
//     } catch (err) {
//     //   console.log('response postImage with error:');
//     //   console.log('err.message', err.message);
//       toast.error(err.message === "canceled" || err.message === "Network Error" ? "Terjadi gagguan di aplikasi, silakan Anda coba lagi" : err.message);
//       return false;
//     }
// }

export const postFile = async (file,fileName,type) => {
  
    const formData = new FormData();
    // if(type === 'image/jpeg' || type === 'image/jpg' || type === 'image/png'){
    //     const options = {
    //         maxSizeMB: 0.5,
    //         useWebWorker: true,
    //         alwaysKeepResolution:true
    //     }
    //     const compressedFile = await imageCompression(file, options);
    //     const fileData = new File(
    //         [compressedFile],
    //         fileName,
    //     { type :'image/jpeg'})
    //     // console.log('file_tobe_uploaded',fileData)
    //     formData.append("file_tobe_uploaded", fileData);
    // }else{
        formData.append("file_tobe_uploaded", file);
    // }
  
    axiosInterceptor()
  
    const optionImage = {
      signal: newAbortSignal(process.env.REACT_APP_TIMEOUT_IMG),
      timeout: process.env.REACT_APP_TIMEOUT_IMG,
      method: 'POST',
      url: process.env.REACT_APP_BACKEND_URL+'/upload_to_server.php',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      retry: process.env.REACT_APP_RETRY_CONNECTION, 
      retryDelay: process.env.REACT_APP_DELAY_TIME, 
      data: formData
    };
  
    // console.log('inside postImage -- before sent');
  
    try {
    //   console.log(optionImage)
      var picture = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
        fileName.substring(0,4) + '/' + fileName.substring(4,6) + '/' 
          + fileName.substring(6,8) + '/' + fileName;
          
      const response = await axios.request(optionImage)
    //   console.log('response postImage with result:',picture);
      return response;
    } catch (err) {
    //   console.log('response postImage with error:');
    //   console.log('err.message', err.message);
      toast.error(err.message === "canceled" || err.message === "Network Error" ? "Terjadi gagguan di aplikasi, silakan Anda coba lagi" : err.message);
      return false;
    }
}

const fetcher = async (url, params, access_token) => {
    // console.log(`Fetching url: ${url} params: ${JSON.stringify(params)} access_token: ${access_token}`)
  
    return await axios({method: 'get', url: url, params: params,
        headers: 
        {
            'Authorization': `Bearer ${access_token}` 
        }}).then(res => res.data)
}

const GetResultSet = (url, params) => {
    var access_token = getToken()
    // if(access_token != null){
        const { data, error, mutate, isValidating } = useSWR(() => access_token ? [url, params, access_token] : null, fetcher)

        // console.log("SWR data: ", data)
        // console.log("SWR url: ", url)
    
        return {
            data,
            isLoading: !error && !data,
            error,
            isValidating,
            mutate
        }
    // }
}

export const useResultSet = async (url, paramOptions) => {
    const { data, error, mutate, isValidating } = GetResultSet(url, paramOptions) 

    return {
        data,
        isLoading: !error && !data,
        error,
        isValidating,
        mutate
    }
}