import axios from "axios"
import useSWR from "swr"

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then((res) => res.data.data)
}

const useOutletServiceRepositories = (location,page,paginate) => {
    const { data, error, mutate, isValidating } = useSWR([`${process.env.REACT_APP_API}/outlet-service?page=`+page+`&is_active=1`+`&paginate=`+paginate+"&link_path="+process.env.REACT_APP_WEB_URL], fetcher)

    if(data?.data.length > 0){
        let new_array = data?.data.map((data) => {
            var dist = distance(location.lat, location.long, data.latitude, data.longitude, "K") * 1000
            data.dist = dist
            return data
        })

        return {
            data : new_array.sort((a, b) => a.dist > b.dist ? 1 : -1),
            ori_data: data,
            isLoading: !error && !data,
            error,
            isValidating,
            mutate
        }
    }

    return {
        data : data,
        isLoading: !error && !data,
        error,
        isValidating,
        mutate
    }
}

function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1/180;
    var radlat2 = Math.PI * lat2/180;
    var theta = lon1-lon2;
    var radtheta = Math.PI * theta/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist;
  }
}

export default useOutletServiceRepositories