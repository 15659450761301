import { useTranslation } from "react-i18next";

export default function MyDevice(props){

    const { i18n, t } = useTranslation();

    return(
        <div className="dashboard-page" style={{height:'100vh'}}>
            <div className="flex flex-row flex-wrap justify-around">
                <div class="py-3 px-5 text-center w-50 rounded bg-white" style={{width:'60%',height:'90vh'}}>
                    <h1 className="font-extrabold text-xl">Chromebook Name</h1>
                    <label className="text-sm">{t('active_warranty')} {t('until')} 6 june 2006</label>
                    <div className="my-5">
                        <img src={require('../assets/images/depan1.png')} className="p-5 items-center m-auto" style={{width:"-webkit-fill-available"}}/>
                    </div>
                    <label className="text-sm">{t('serial_number')}</label>
                    <h2 className="font-bold text-md">{t('w0rl6bu2n3r666g0dd3snyx')}</h2>
                </div>
                <div class="py-10 px-5 text-left w-50 rounded" style={{width:'35%',height:'90vh',backgroundColor:"#E8E8E8"}}>
                    <h5 className="font-extrabold">{t("system_information")}</h5>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("processor")}</label>
                        <label className="text-sm">Intel Celeron N4020</label>
                    </div>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("memory")}</label>
                        <label className="text-sm">2 GB</label>
                    </div>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("storage")}</label>
                        <label className="text-sm">120GB</label>
                    </div>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("screen")}</label>
                        <label className="text-sm">13 Inch</label>
                    </div>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("camera")}</label>
                        <label className="text-sm">2 MP</label>
                    </div>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("material")}</label>
                        <label className="text-sm">Polikarbonat</label>
                    </div>
                    <div class="grid grid-cols-2 gap-4 my-5">
                        <label className="text-sm">{t("system_operation")}</label>
                        <label className="text-sm">Chrome OS Ver 6.6.6</label>
                    </div>
                </div>
            </div>
        </div>
    )
}