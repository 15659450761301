import React, { useState,useEffect } from 'react';
import { postData } from '../helpers/api-helper';
import { setSession } from '../helpers/auth-helper';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function ForgotPassword(props){

    const { t } = useTranslation();
    const [email,set_email] = useState("")
    const [verification,set_verification] = useState(false)
    const [verification_code,set_verification_code] = useState("")
    const [new_password,set_new_password] = useState("")
    const [confirm_password,set_confirm_password] = useState("")

    const [loading,set_loading] = useState(false)
    const [seconds,set_seconds] = useState(0)

    const navigate = useNavigate()

    const Submit=async()=>{
        var params = {email:email}
        set_loading(true)
        await postData(`${process.env.REACT_APP_API}/forgot-password`,params).then((response) => {
            if(response.status === 200){
                toast.success('Token is sended')
                set_loading(false)
                set_verification('created')
            }
        }).catch((e) => {
            set_loading(false)
            toast.success('Failed to send token')
        })
    }

    const resendToken=async()=>{
        var params = {email:email}
        set_loading(true)
        await postData(`${process.env.REACT_APP_API}/resend-token-forgot-password`,params).then((response) => {
            if(response.status === 200){
                set_seconds(10)
                toast.success('Token Resended')
            }
            set_loading(false)
        }).catch((e) => 
            set_loading(false))
    }

    const verifyToken=async ()=>{
        var params = {email:email,otp:verification_code}
        set_loading(true)
        await postData(`${process.env.REACT_APP_API}/verify-token-forgot-password`,params).then((response) => {
            if(response.data.status_code === 200){
                set_verification('verified')
                toast.success('Verification Success')
                set_verification_code("")
            }else{
                toast.error(response.data.message)
            }
            set_loading(false)
        }).catch((e) => 
            set_loading(false))
    }

    const validate=()=>{
        if(confirm_password != new_password){
            toast.error(t('auth.password_mismatch'));
        }else{
            SubmitNewPassword();
        }
    }

    const SubmitNewPassword=async()=>{
        var params = {email:email,new_password:new_password,confirm_password:confirm_password}
        set_loading(true)
        await postData(`${process.env.REACT_APP_API}/create-new-password`,params).then((response) => {
            if(response.data.status_code === 200){
                toast.success('New Password Created')
                navigate('/login')
            }else{
                toast.error(response.data.message)
            }
            set_loading(false)
        }).catch((e) => 
            set_loading(false))
    }

    const FormPage=()=>{
        if(verification === 'created'){
            return(
                <>
                <div className='px-8 py-5'>
                    <h5 className='text-left text-5xl font-bold'>We have sent you a verification code through your email {email}</h5>
                </div>
                <div className="mt-10 grid px-8">
                    <div className="my-5">
                        <input type="text" name="verification_code" id="verification_code" placeholder='Verification code' onChange={(e) => set_verification_code(e.target.value)} value={verification_code} className="block w-full input-1"/>
                    </div>
                </div>
                <div className="mt-10 px-8">
                    { loading ? 
                    <div role="status" className="mt-2 flex justify-center">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div> : 
                    <>
                    <div className="mt-2 flex justify-center">
                        <button className='btn-register' onClick={(e) => verifyToken()}>Verify</button>
                    </div>
                    <div className="mt-2 flex justify-center">
                        <p className='color-lvl-4'>Or</p>
                    </div>
                    <div className="mt-2 flex justify-center">
                        <button className={'btn-resend'} onClick={(e) => resendToken()} disabled={seconds > 0}>Resend {seconds > 0 ? "("+seconds+")" : ""}</button>
                    </div>
                    </>}
                </div>
                </>
            )
        }else if(verification === 'verified'){
            return(
                <>
                <div className='px-8 py-5'>
                    <h5 className='text-left text-5xl font-bold'>Enter your new password</h5>
                </div>
                <div className="mt-10 grid px-8">
                    <div className="my-3">
                        <input type="password" name="new_password" id="new_password" placeholder='New Password' onChange={(e) => set_new_password(e.target.value)} value={new_password} className="block w-full input-1"/>
                    </div>
                    <div className="my-3">
                        <input type="password" name="confirm_password" id="confirm_password" placeholder='Confirm Password' onChange={(e) => set_confirm_password(e.target.value)} value={confirm_password} className="block w-full input-1"/>
                    </div>
                </div>
                <div className="mt-10 px-8">
                    { loading ? 
                    <div role="status" className="mt-2 flex justify-center">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div> : 
                    <>
                    <div className="mt-2 flex justify-center">
                        <button className='btn-register' type='button' onClick={(e) => validate()}>Submit</button>
                    </div>
                    </>}
                </div>
                </>
            )
        }else{
            return(
                <>
                    <div className='px-8 py-5'>
                        <h5 className='text-left text-5xl'>Hello,</h5>
                        <h5 className='text-left text-5xl font-bold'>Enter your email for us to send a token</h5>
                    </div>
                    <div className="mt-1 grid px-8">
                        <div className="my-3">
                            <input type="email" name="email" id="email" placeholder='Email Address' onChange={(e) => set_email(e.target.value)} value={email} className="block w-full input-1"/>
                        </div>
                    </div>
                    <div className="mt-10 px-8">
                        { loading ? 
                            <div role="status" className="mt-2 flex justify-center">
                                <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div> : 
                            <div className="mt-2 flex">
                                <button type='button' className='btn-register' onClick={(e) => Submit()}>Submit</button>
                            </div>
                        }
                    </div>
                </>
            )
        }
    }

        return(
            <form>
                <div class="grid grid-cols-2 gap-4 mb-4">
                    <div className="py-16 px-10">
                        <header className="pl-10 flex">
                            <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width={'7%'}/>
                            <h2 className='my-auto ml-5 font-semibold text-2xl color-2'>Vancare App</h2>
                        </header>
                        {FormPage()}
                    </div>
                    <div className="background-page">
                    </div>
                </div>
            </form>
        );
}