import { useState, useContext, createContext } from "react"


const storage_notification = localStorage.getItem('notifications')

// Initial state
const initialState = {
    notifications: JSON.parse(storage_notification),
    set_notifications: () => { },
}

// Page context
export const PageContext = createContext(initialState)

// Provider
export const PageProvider = ({ children }) => {
    const [notifications, set_notifications] = useState([])

    const value = {
        notifications,
        set_notifications,
    }

    return (
        <PageContext.Provider value={value}>
            {children}
        </PageContext.Provider>
    )
}

export const usePage = () => {
    return useContext(PageContext)
}