import React, {useState, useEffect} from 'react'
import {requestForToken, onMessageListener} from './firebase';
import toast from 'react-hot-toast';
import { getUser } from '../helpers/auth-helper';
import { usePage } from '../helpers/page-context';

const Notification = () => {
    const {set_notifications} = usePage()
    const data_user = getUser()
    if(data_user){
      // const user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      // requestForToken(user_id);
    }

    onMessageListener()
        .then((payload) => {
            console.log(payload,"Received Message")
            toast(payload?.notification?.title + "\n" + payload?.notification?.body, {
                duration: 4000,
                position: 'top-center',
              
                // Styling
                style: {textAlign:'center'},
                className: '',
              
                // Custom Icon
                icon: '🔔',
              
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
              
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
                params:{
                  link : payload?.fcmOptions.link
                }
              });
            createNotification(payload)
        })
        .catch((err) => console.log('failed: ', err));

      const createNotification=(new_notification)=>{
        if(new_notification?.notification?.title != "Register Device" && new_notification?.notification?.title != "Device Already Registered"){
          const storage_notification = localStorage.getItem('notifications');
          var old_notification =  storage_notification ? JSON.parse(storage_notification) : []
          old_notification.push(new_notification)
          localStorage.setItem('notifications',JSON.stringify(old_notification))
          set_notifications(old_notification)
        }
      }
}

export default Notification