
export default {
    translation: {
        title: 'Vancare App',
        sidebar:{
            dashboard: 'Dashboard',
            my_device: 'My Device',
            interesting_promo: 'Interesting Promo',
            news: 'News',
            customer_service: 'Customer Service',
            service_center: 'Service Center',
            settings: 'Settings',
            notifications:"Notification",
        },
        empty:"Empty",
        serial_number: "Serial Number",
        apps_update :"Update App",
        update: "Update",
        active_warranty: "Active Warranty",
        until: "Until",
        system_information:"System Information",
        processor:"Processor",
        memory:"Memory",
        storage:"Storage",
        screen:"Screen",
        camera:"Camera",
        material:"Material",
        system_operation:"System Operation",
        source: "Source",
        address: "Address",
        outlet_detail: "Outlet Detail",
        contact_name: "Contact Name",
        contact_email: "Contact Email",
        contact_number: "Contact Number",
        hello:"Hello",
        welcome:"Welcome!",
        send_request:"Send Request",
        request_sended:"Request Sended",
        back:"Back",
        news_detail:"News Detail",
        registered:"Registered",
        expired_at:"Expired At",
        auth:{
            login:"Login",
            register:"Register",
            sign_up:"Sign Up",
            remember_me:"Remember Me",
            forgot_password:"Forgot Password?",
            message_verification:"We have sent you a verification code through your email",
            message_name_diff:"Email registered before, but full name is different from the last time email registered, please confirm which name you will use",
            change_email:"Change Email",
            full_name:"Full Name",
            new_full_name:"New Full Name",
            phone:"Phone Number",
            placeholder_phone:"Phone Number start with 08xxxxxxxx",
            new_email:"New Email",
            verify:'Verify',
            resend:'Resend',
            current_password:"Current Password",
            new_password:"New Password",
            password_mismatch:"Password Mismatch",
            apply:'Apply',
            password_is_too_short:"Password is too short (min 8 character)",
            cancel:'Cancel',
            serial_number_required:"Serial Number Required",
            password_required:"Password Required",
            phone_required:"Phone Number Required",
            confirm_password_required:"Confirm Password Required",
            choose_sn:"Please confirm which device you are using",
            user:{
                not_found:"User not Found",
                not_verified:"User Hasn't verify token"
            },
            token:{
                verified:"Account Verified",
                invalid:"Token Invalid",
            },
            alert:{
                verify_success_but_name:"This email has registered before, but full name different from the last time",
                verify_success:"Verification Success",
                change_email_success:"Change Email Success",
                email_format_invalid:'Email format invalid',
                email_format_valid:'Email Format Valid',
                register_success:"Register Success",
                account_exists:"Account Exists",
                token_resended:"Token Resended",
                change_name_success:"Change Name Success",
                change_password_success:"Change Password Success",
                serial_number_already_exist:"Serial Number already registered",
                device_hasnot_registered:"Device Has not registered, please register",
                serial_number_not_for_this_user:"Device Serial Number are not registered for this email, Send request for new owner"
            }
        },
        change_email:"Change Email",
        change_password:"Change Password",
        change_profile:"Change Profile",
    },
    network_error:"Somethings wrong with the internet connection (No Internet)",
    warranty_expired:"Warranty Expired",
}

