import React, { useEffect } from 'react';
import $ from 'jquery';
import { getUser } from '../helpers/auth-helper';

const CustomerService = () => {

  const user = getUser()

  useEffect(() => {
    // Create the first script element for qchatInitialize.js
    const qchatInit = document.createElement('script');
    qchatInit.src = "https://webchat.qontak.com/qchatInitialize.js";

    // Create the second script element for app.js
    const qchatWidget = document.createElement('script');
    qchatWidget.src = "https://webchat.qontak.com/js/app.js";

    // Append scripts to document head
    document.head.prepend(qchatInit);
    document.head.prepend(qchatWidget);

    // Set up initialization after qchatInit has loaded
    qchatInit.onload = function() {
      if (window.qchatInitialize) {
          window.qchatInitialize({
            id: "ff254a01-87ab-4eef-9788-48eff8096ccf",
            code: "33lxjP6JlmObxcOwreFomQ"
          });

          $(function() {
              $("input[name='Name']").val('Test Test');
              $("input[name='Email']").val(user.email);
          });
      }
    };

    // Clean up script tags on unmount
    return () => {
      document.head.removeChild(qchatInit);
      document.head.removeChild(qchatWidget);
    };
  }, []);

  return null; // No UI for this component, it only loads the chat widget
};

export default CustomerService;