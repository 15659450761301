

export default function Promo(){

    return(
        
        <div className="dashboard-page">
            <iframe src="https://www.advan.id/shop-2/" height="100%" width="100%"/>
        </div>
    )
}