import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnPack from './lang/en';
import IdPack from './lang/id';

i18n.use(initReactI18next).init({
  lng: "id",
  fallbackLng: "id",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: EnPack,
    id: IdPack,
},
});

export default i18n;